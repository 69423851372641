import React, { useState } from 'react'
import './loginsignup.css'
import person from '../components/Assests/person.png'
import email from '../components/Assests/email.png'
import password from '../components/Assests/password.png'

const Loginsignup = () => {
  const [action,setAction]=useState("Sign Up");
  return (
        <div className="box">
          <div className="heading">
        <h1>{action}</h1>
        <div className="underline"></div>
          </div>
          <div className="inputs">
            {action=="Login"?<div></div>: <div className="input">
              <img src={person} alt="" className="src" />
              <input type='text' placeholder='Name'></input>
            </div>}
          <div className="input">
            <img src={email} alt="" className="src" />
            <input type='text' placeholder='Email-id'></input>
          </div>
        <div className="input">
          <img src={password} alt="" className="src" />
          <input type='text' placeholder='Pasword'></input>
        </div>
        </div>
        {action=="Sign Up"?<div></div>: <div className="forgetpass">
        <span>Forget password ? </span>
        <span>Click here</span>
        </div>}
       <div className={action=="Sign Up"?"submit-margin":"submit-container"}>
        <div className={action==="Login"?"submit gray":"submit"}onClick={()=>{setAction("Sign Up")}}>Sign Up </div>
        <div className={action==="Sign Up"?"submit gray":"submit"}onClick={()=>{setAction("Login")}}>Login</div>
       </div>
        
      </div>
  )
}

export default Loginsignup
